@import 'mixins.scss';

.vrtl-qualifications-card {

    &__container {
        box-shadow: 0 2px 1px 0 #ddddddc4;
        border: 1px solid #d2d2d2;
        border-radius: .4rem;
        margin: .5rem 1rem;
        box-sizing: border-box;
        background-color: white;
        flex:1;
        display: flex;
        flex-direction: column;
    }

    &__logo-container {
        padding: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 5rem;
    }

    &__text {
        flex: 1;
    }

    &__button {
        background-color: white;
        margin:.5rem 1rem;
        padding:1rem;
        font-size: 1.2rem;
        border-radius: .2rem;
        border: .1rem solid $gray-border;
        text-align: center;
        color:$gray-text;
        cursor: pointer;
        transition: .2s background-color, .2s color;
        width: 100%;
    }

    &__button:hover {
        background-color: $action-hover;
        color:#656464;
    }

    &__footer {
        display: flex;
    }

    &__logo {
        width: 100%;
    }
}

.vrtl-process-short-steps{
    padding:.5rem 1rem;
    flex:1;
}

.vrtl-step-card--action-btn {
    text-align: center;
    background-color: #008FBE;
    cursor: pointer;
    color: white;
    font-weight: 600;
    border-radius: 0.2rem;
    padding: .7rem 2rem;
    max-width: 30rem;
    margin: 0 auto;
}