// Deprecated, please use sx with media query constant
@mixin for-phone-only {
    @media (max-width: 620px) { @content; }
}

// Deprecated, please use sx with media query constant
@mixin for-nonphone-only {
    @media (min-width: 620px) { @content; }
}

// Deprecated, please use sx with media query constant
@mixin for-tablet-portrait-down {
    @media (max-width: 768px) { @content; }
}
