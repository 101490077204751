@import '../../media-mixins';
@import '../../colors';
@import 'mixins.scss';

.vrtl-edit-delegates-form{
    @include new-labeled-row;

}

.vrtl-delegate-card{
    @include new-labeled-row;
    &__container {
        @include for-tablet-portrait-down {
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;
        }
    }
    &__icon-offset {
        @include for-tablet-portrait-down {
            display: none;
        }
    }
}

.vrtl-delegate-order-by{
    @include new-labeled-row;
    @include for-tablet-portrait-down {
        flex-direction: column;
    }
    & > div {
        @include for-tablet-portrait-down {
            justify-content: space-between;
        }
    }
    &--margin-shrink{
        padding:.8rem;
        margin: .5rem 1rem;
    }
}

.vrtl-delegate-form{
    @include icons;
    @include new-labeled-row;
}

.delegate-form-conatiner{
    padding:.8rem;
    min-width: 50rem;
}
.delegate-form{
    display:flex;
    flex-wrap: wrap;
    background-color: white;
    font-size: 1.2rem;
    align-items: flex-end;
}

.delegate-form-name-section{
    padding-right: 1rem;
    flex:1;
}
.delegate-form-email-section{
    padding-right: 1rem;
    flex:2;
}
.delegate-form-dropdown-container{
    padding-right: 1rem;
}
.delegate-form-header{
    color:$gray-header;
    margin: .2rem 0;
}
.delegate-form-conatiner  .card-section{
    margin: 0 1rem;
}

.delegates-container{
    padding:1rem;
}
.delegates-header{
    color:$gray-header;
}

.current-user{
    border-color:#016ee0;
}

.delegate-card-section{
    min-width: 10rem;
    flex:1;
    display:flex;
    flex-direction: column;
    text-transform: capitalize;
}

.delegate-card-button-container{
    min-width: 10rem;
    flex:1;
    display:flex;
    justify-content: flex-end;
    text-transform: uppercase;
}
