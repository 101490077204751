@import '../colors';

.distribute-allocation-left-main{
    padding:1rem;
    flex:1;
    display: flex;
    flex-direction: column;
}

.buyer-action-section-top{
    flex:3;
    display:flex;
}

.buyer-action-section-middle{
    flex:3;
}

.buyer-action-section-bottom{
    flex:4;
    display:flex;
}

.buyer-action-form-container{
    flex:1;
}

.buyer-action-header{
    margin:0 2rem;
}

.buyer-action-qualifications-container{
    display: flex;
}

.buyer-action-qualifications-card{
    max-width: 12rem;
    display:block!important;
}
.distribution-qualification-trigger{
    margin:auto 0;
    padding:3.5rem 2rem;
}
.buyer-action-card-mark-as{
    display: flex;
    align-items: center;
    color:$gray-text;
    font-weight: 800;

}

.buyer-action-card-section{
    padding:.6rem;
    margin:.4rem;
    font-weight: 800;
    color:$gray-text;
    cursor: pointer;
    border: .1rem solid #737373;
    border-radius: .4rem;
    transition: .2s background-color;
}
.buyer-action-card-section:hover{
    background-color: rgba(156, 235, 77, 0.33);
}
.update-investment-status-form{
    padding:1rem;
    min-width: 60rem;
}
.update-investment-bulk-edit-text{
    font-size: .8rem;
    color:$gray-text;
    font-weight: 800;
    padding-right: 3rem
}

.update-investment-input-container{
    max-width: 10rem;
}
.add-customers-card{
    width:100%;
    display: flex;
}

.investor-form-section{
    margin: 0 2rem;
}
.buyer-form-button{
    float:right;
    margin-right: 3rem;
}
.buyer-form{
    flex:1;
}

.distribute-allocation-bread-container{
    background-color: blue;
    background: linear-gradient(270deg,#009ce8,#0660c1,#037cb5);
    background-size: 800%;
    border-radius: 0 0 2rem 0;
    padding:.3rem 1.5rem .3rem 1rem;
}

.distribute-allocation-bread-text{
    color:white
}
