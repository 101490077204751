@import '../../colors';
.loading-pulse{
  fill: white;
  animation-duration: 1s;
  animation-name: ripple;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  height:4rem;
  width: 4rem;
}
.loading-pulse-container{
    height:100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loading-text{
    text-transform: uppercase;
    color: $gray-text;
    font-size: 1.4rem;
    font-weight: 200;
    letter-spacing: .2rem;
    margin-top: 1rem;
}
.loading-dots{
    width: 3rem;
    margin-left: calc(50% - 1.5rem);
}

.loading-default-container {
    margin: 2rem
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
}

@keyframes pulse {
  from {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(1);
  }
  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
}
