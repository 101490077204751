@import '../mixins';

.vrtl-round-incoming-cap {
    @include basic-heading-elements;

    @include labeled-row;

    &__container {
        flex: 1;
    }

    &__row, &__header {
        padding: 0;
    }

    &__row {
        height: 2rem;
    }

    &__header {
        height: 2.5rem;
    }

    &__column-arrow {
        transition: transform 100ms ease-out;
        display: flex;
        
        &, & > svg {
            font-size: 2rem;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__column-arrow--open {
        transform: rotate(90deg);
    }
}

.vrtl-round-cap-deploys {
    @include labeled-row;

    @include basic-heading-elements;

    &__add {
        color: #1eda1a;
    }
}

.vrtl-round-capman__container {
    display: flex;
    flex:1;
}

.vrtl-round-capman__column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.vrtl-round-incoming-cap__order-by-container{
    position: relative;
    flex:1;
}
