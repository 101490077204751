@import '../colors';
@import 'mixins/containers.scss';
@import 'mixins/text.scss';
@import '../media-mixins';

.vrtl-account-page {
    @include flex-containers-282;
    @include text;
    &__container {
        @include for-tablet-portrait-down {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &__tab-nav {
        @include for-tablet-portrait-down {
            flex-wrap: wrap;
        }
    }
}
.vrtl-account-delegates {
    flex: 1;
    @include for-tablet-portrait-down {
        width: 100vw;
    }
}

.account-info-container{
    padding:1rem;
    min-width: 22rem;
}
.account-info-header{
    color:$gray-header;
}
.account-info-row{
    margin-left: 1rem;
    display: flex;
    height:3rem;
    align-items: center;
}
.account-info-label{
    width: 4rem;
}
.large-account-info-label{
    width: 7rem;
}
.account-info-button{
    margin: .5rem 0 0 5rem;
    float:left;
}
.account-info-button-container{
    display:flex;
    justify-content: space-between;
}
.account-top-container{
    margin:0 10rem 0 2rem;
    display:flex;
    justify-content: space-between;
}

.brand-bg-primary-color {
    background-color: #005289;
}

.brand-bg-secondary-color {
    background-color: #65C7C7;
}

.brand-bg-tertiary-color {
    background-color: #0090B9;
}