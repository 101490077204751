
.route-popup-content{
    border: none!important;
    padding:0!important;
    width: auto!important;
    min-width:35rem!important;
    max-width: 60rem!important;
    border-radius: 1.6rem;
}
.popup-inner{
    padding: 1.6rem;
}

.popup-inner > *:last-child{
    padding-top:0!important;
    margin-top: 0!important;
}

.close-icon-container{
    box-sizing: border-box;
    margin-left: calc(100% - 2.5rem);
    padding: .5rem .5rem 0 0;
}

.close-icon-container:hover {
    cursor: pointer;
}

.close-icon-container * {
    font-size: 2rem!important;
    color:$gray-header;
}
.invisable-popup.popup-content{
    background-color: rgba(255, 255, 255, 0)!important;
}
.invisable-popup-close{
    padding:0!important;
    color:white;
    position: relative;
    top:2.5rem;
    right:.5rem;
}
@media screen and (max-width: 800px) {
    .popup{
        width:100vw!important;
        max-height:80vh!important;
    }
}
