
@import '../colors';

@mixin flex-containers-282{
    &__container {
        display: flex;
    }

    &__container--column {
        display: flex;
        flex-direction: column;
    }

    &__container--2 {
        flex: 2;
        display: flex;
    }

    &__container--octuple {
        flex: 8;
        display: flex;
        flex-direction: column;
    }

    &__heading {
        padding-top: 2rem;
    }

    &__avatar {
        width: 2rem;
        height: 2rem;
        background-color: #ffffff59;
    }

    &__user {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        border-radius: 6px;
        margin-top:1.5rem;
        padding: 1.5rem;
        box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
    }

    &__user--spacer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        border-radius: 6px;
        margin-top: 1rem;
    }

    &__info {
        flex: 6;
    }

    &__section--octuple{ 
        flex: 8;
    }
}

@mixin nav-bar--tab {
    &__container {
        display: flex;
    }

}