@import './colors';
@import 'mixin-modifiers.scss';

.card-container{
    @include colors;
    box-shadow: 0 2px 1px 0 #ddddddc4;
    border: 1px solid #d2d2d2;
    border-radius:.1rem;
    margin:.5rem 1rem;
    // flex:1;
    box-sizing: border-box;
    background-color: white;
}

.padded-card-container{
    box-shadow: 0 2px 1px 0 #ddddddc4;
    border: 1px solid #d2d2d2;
    border-radius:.1rem;
    margin:.5rem 1rem;
    flex:1;
    padding:0 .8rem .8rem .8rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.card-arrow-container{
    flex:1;
    display:flex;
    align-items: center;
}
.card-arrow-container > .card{
    padding-left: .2rem!important;
}
.card{
    flex:1;
    box-sizing: border-box;
    display:flex;
    flex-wrap: wrap;
    padding:.8rem;
    background-color: white;
    align-items: center;
}

.card--padding{
    padding: 0.8rem;
}

.card-section{
    min-width: 10rem;
    flex:1;
}
.small-card-section{
    flex:1;
}

.card-label{
    font-size: .6rem;
    font-weight: 800;
    color:$gray-text;
}
.card-form-trigger{
    background-color: white;
    margin:.5rem 1rem;
    padding:1rem;
    font-size: 1.2rem;
    border-radius: .2rem;
    border: .1rem solid $gray-border;
    text-align: center;
    color:$gray-text;
    cursor: pointer;
    transition: .2s background-color, .2s color;
}
.card-form-trigger:hover{
    background-color: $action-hover;
    color:#656464;
}



.compact-card-form-trigger{
    background-color: white;
    margin:.5rem 1rem;
    padding:.5rem;
    font-size: 1rem;
    border-radius: .2rem;
    border: .1rem solid $gray-border;
    text-align: center;
    color:$gray-text;
    cursor: pointer;
    transition: .2s background-color, .2s color;
}
.compact-card-form-trigger:hover{
    background-color: $action-hover;
    color:#656464;
}

.card-button{
    float:right;
    font-size: .75rem;
    background-color: #737373;
    font-weight: 800;
    color:white;
    text-align: center;
    padding: .8rem 1.5rem;
}
.card.nested{
    padding: .8rem 3rem;
}
.dropdown-arrow{
    font-size: 1.4rem!important;
    cursor: pointer;
}

.card-logo{
    height:1.5rem!important;
}
.card-logo svg{
    height:1.5rem!important;
}
