.class-form-three{
    display: flex;
    justify-content: space-around;
}
.class-form-three-section{
    padding:1rem;
}
.option-select-option{
    display:flex;
    align-items: center;
}

.token-container {
    flex: 1 1;
    box-sizing: border-box;
    display: flex;
    padding: 0.8rem 3rem;
    background-color: white;
    align-items: center;
}