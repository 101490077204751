.broker-allocation-form{
    min-width: 35rem;
    flex-wrap: wrap;
    background-color: white;
}
.broker-allocation-form-section{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.broker-allocation-form-heading {
    font-weight: 500;
    font-size: 1.5rem;
    color: #252525;
    margin-bottom: 1.6rem;
    margin-top: -2rem;

}