@import '../mixins';

.vrtl-asset-class-distribution{
    @include buyer-row;
    @include positioning;
    @include spacing;
}

.vrtl-asset-class-holding{
    @include buyer-row;
    @include positioning;
    @include spacing;

    &__container::before{
        border-left: .12rem solid #0030ff;
    }
}

.vrtl-asset-class-token{
    @include buyer-row;
    &__container::before{
        content:"";
        border-left: .12rem solid #018f53;
        padding-right: .1rem;
    }
}
.vrtl-asset-class-distributions{
    position: relative;
    flex: 1 1;
    background-color: #f4f4f5;
    min-height: 20rem;
    min-width: 45rem;
    margin-bottom: 0.8rem;
}

.vrtl-poi-card{
    @include new-labeled-row;
}

.vrtl-poi {
    @include buttons;
}