@import '../colors';

.vrtl-integration-requirement-form{
    min-width: 35rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    margin: 0 auto;
}

.vrtl-integration-requirement-form__tombstone{
    flex:1;
    display: flex;
    flex-direction: column;
}

.vrtl-integration-requirement-form__body{
    flex:1;
    display: flex;
    flex-direction: column;
}

.vrtl-integration-requirement-form__row{
    padding: .5rem;
    background-color: white;
}

.vrtl-integration-requirement-form__label{
    font-size: 1rem;
    text-transform: capitalize;
}

.vrtl-integration-requirement-form__input{
    padding:.5rem!important;
    font-size: 1.2rem!important;
    margin-top: .4rem!important;
}

.vrtl-integration-requirement-form--header{
    color:$gray-header;
    text-align: center;
    font-size: 2rem;
}

.vrtl-process-info{
    padding-bottom: .5rem;
}

.vrtl-process-name{
    text-align: center;
}

.vrtl-process-message{
    color: #737373;
}
