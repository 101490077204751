$gray-text:#737373;
$gray-header:#5e5e5e;
$gray-border:#d2d2d2;
$gray-200: #E3E3E3;
$gray-700: #333333;
$action-hover:rgba(156, 235, 77, .4);
$checkbox-green:#aee775;
$blue-dark: #004a98;
$blue-main: #008fbe;
$vtlo-blue-light: #0090B9;
$vtlo-teal: #65C7C7;
$teal-light: #62cbc9;
$shadow: #ddddddc4;
$orange: #e3a360;