.tombstone{
    padding:1rem;
    box-sizing: border-box;
    flex:1;
    display: flex;
    flex-direction: column;
}
.tombstone-row{
    display: flex;
    padding: .5rem;
    display: flex;
    align-items: center;
    height:2rem;
    background-color: white;

}
.tombstone-split-row input{
    max-width: 13rem;
}
.tombstone-item-right{
    text-align: right;
}
.tombstone-split-row{
    display: flex;
    padding: .5rem;
    display: flex;
    align-items: center;
    height:2rem;
    justify-content: space-between;
    background-color: white;
    
}
.tombstone-item{
    flex:1;
}
