@import '../mixins.scss';

.vrtl-token-distribution{
    @include buyer-row;
}

.vrtl-token-holding{
    @include buyer-row;
    &__container::before{
        border-left: .12rem solid #0030ff;
    }
}

.vrtl-token-chain{
    @include buyer-row;
    &__container::before{
        content:"";
        border-left: .12rem solid #018f53;
        padding-right: .1rem;
    }
}

.vrtl-token-distributions{
    position: relative;
    flex: 1 1;
    background-color: #f4f4f5;
    min-height: 15rem;
}

.vrtl-token-buttons {
    display: flex;
    align-items: center;
}