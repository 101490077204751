@import '../colors';

@mixin row{
    &__content {
        flex: 1;
    }

    &__content--spacer-triple{
        flex: 3;
    }

    &__label {
        flex: 1;
    }

    &__line{
        border: 0.1px solid $gray-border;
        margin-left: 0.5rem;
    }

}
