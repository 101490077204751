@import '../mixins';

.vrtl-report-download {
    @include labeled-row;

    @include basic-heading-elements;
}

.vrtl-reports-download__container {
    display: flex;
    flex:1;
}

.vrtl-reports-download__column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

