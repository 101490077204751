@import '../../colors';
@import 'mixins.scss';

.vrtl-transfer-ownership-row {
    @include new-labeled-row;
    &__sub-text {
        color:$gray-text;
        font-size: .8rem;
        margin-bottom: .4rem;
    }
    &__container{
        box-shadow: none!important;
    }
    &__header{
        margin-bottom: 1rem;
    }
}

.vrtl-transfer-ownership-form {
    @include new-labeled-row;
}

.vrtl-transfer-ownership__button {
    padding: 1.2rem;
    border: .1rem solid $gray-border;
    border-radius: .3rem;
    transition: .2s background-color;
    color:$gray-text;
    cursor: pointer;
    background-color: white;
    width: fit-content;
    :hover{
        background-color: $action-hover;
    }
}

.transfer-ownership-warning{
    color:$gray-text;
    font-size: .8rem;
    margin-bottom: .4rem;
}
.transfer-ownership-input-container{
    display: flex;
    align-items: flex-end;
    font-size: 1.2rem;
    color:$gray-header;
}

.transfer-ownership-button-container{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
