.grid-row{
    display:flex;
    /* flex-wrap: wrap; */
}

.grid-column{
    display:flex;
    flex-direction: column;
}

.grid-item{
    display:flex;
}
.grid-row:nth-child(n+2){
    margin-top:.5rem;
}
.grid-row .grid-item:nth-child(n+2){
    margin-left: .5rem;
}
.grid-column:nth-child(n+2){
    margin-left: .5rem;
}
.grid-column .grid-item:nth-child(n+2){
    margin-top: .5rem;
}
