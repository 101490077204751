@import './media-mixins';
@import './colors';
@import './mixin-modifiers.scss';


@mixin entity-page {
    @include borders;
    @include icons;
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__header {
        margin: 1.25rem;
        font-weight: bold;
        font-size: 1.2rem; 
    }

    &__container--table {
        min-width: 50%;
    }

    &__table {
        border: 0.1rem solid #d2d2d2;
        border-radius: 0.4rem;
        justify-content: center;
        margin: 0 1.25rem;
        flex: 1;
    }
    
    &__card {
        display: flex;
    }

    &__section {
        flex: 1;
    }
}

@mixin card {
    @include icons;
    &__container {
        flex: 1;
        border: 0.1rem solid #d2d2d2;
        border-radius: 0.4rem;
        background-color: #E3E3E3;
        color: #333333;
        padding: 0.8rem;
        margin: 1.25rem 1.25rem 1.25rem 1.25rem;
    }

    &__header {
        font-weight: 800;
        font-size: 1.6rem;
        margin: 0.8rem 0.8rem 0.8rem 0.8;
    }

    &__section {
        flex: 1;
    }

    &__section--2 {
        flex: 2;
    }

    &__row {
        display: flex;
        gap: 1rem;
    }

    &__heading {
        font-size: .6rem;
        font-weight: 800;
        line-height: 1.2rem;
        color: #333333;
    }

    &__link {
        color: #333333;
        text-decoration: underline;
    }
}

// Deprecated.
@mixin labeled-row {
    &__container {
        border: 1px solid #c1c5c8;
        border-radius:.1rem;
        margin:.5rem 1rem;
        flex:1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background-color: white;
    }

    &__row, &__header {
        display: flex;
        padding: 0.3rem 0.8rem;
        justify-content: space-between;
        align-items: center;
    }

    &__header {
        background-color: #f0f0f0;
        color: #5b6670;
    }

    &__row:not(:last-child), &__header:not(:last-child) {
        border-bottom:  1px solid #c1c5c8;
    }

    // below deprecated

    &__header > &__column {
        font-size: 0.8rem;
    }

    &__column {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
    }

    &__column--2 {
        flex: 2 1;
    }

    &__column--3 {
        flex: 3 1;
    }

    &__column--shrink {
        flex-grow: 0;
        min-width: 2rem;
    }

    // TODO: __add and __add-button need to go
    &__add:hover {
        cursor: pointer;
    }

    &__add > svg {
        font-size: 1.5rem;
    }

    &__add-form {
        padding: 1rem;
        min-width: 25rem;
    }

    &__add-button {
        border-radius: 0.2rem;
    }

    &__add-button:hover {
        cursor: pointer;
    }
}

@mixin basic-heading-elements {
    &__heading {
        margin: .3rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__heading-text {
        font-weight: 500;
        font-size: 1.5rem;
        color: #252525;
    }

    &__heading-action, &__heading-action > svg {
        transition: color 0.2s ease;
        font-size: 1.75rem;

        &:hover {
            cursor:  pointer;
        }
    }

    &__heading-action {
        display: flex;
    }
}

@mixin basic-nav-elements {
    &__container {
        display: flex;
        padding: 1rem;
        background-color: white;
    }

    &__tab {
        color: $blue-dark;
        font-size: 1.25rem;
        font-weight: bold;
        padding: 1rem 0;
        margin-right: 2rem;
        position: relative;
        text-decoration: none!important;
    }

    &__tab:after {
        transition: background-color ease 0.2s;
        border-radius: 0.5rem;
        top: 2.75rem;
        content: ' ';
        height: 0.25rem;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &__tab:hover {
        cursor: pointer;
    }

    &__tab:hover:after {
        background-color: $teal-light;
    }

    &__tab--active:after {
        background-color: $blue-dark;
    }
}

.mixni-icon{
    margin:auto 0;
    font-size: 1.4rem!important;
    cursor: pointer;
}

@mixin icons{
    &__icon-link{
        font-size:1rem!important;
        width: 1.4rem!important;
        cursor: pointer;
    }
    &__icon-edit{
        @extend .mixni-icon;
        @include overrides;
        color:gray;
    }
    &__icon-submit{
        @extend .mixni-icon;
        color:green;
    }
    &__icon-cancel{
        @extend .mixni-icon;
        color:red;
    }
    &__icon-delete{
        @extend .mixni-icon;
        color:gray;
    }
    &__icon-add{
        @extend .mixni-icon;
        color:#1eda1a;
    }
    &__icon-add--offset {
        @extend .mixni-icon;
        color:#1eda1a;
        height: 1rem!important;
    }
    &__icon-open-in-new{
        @extend .mixni-icon;
    }
    &__icon-help{
        @extend .mixni-icon;
    }

    // Does not extend mixni-icon because its margin misaligns the icon
    &__icon-download{
        margin: 0!important;
        font-size: 1.4rem!important;
        cursor: pointer;
    }
    
    &__icon-checkbox{
        margin:auto 0;
        font-size:1.2rem!important;
        width: 1.4rem!important;
        cursor: pointer;
    }
    &__icon-copy{
        margin:auto 0;
        font-size: 1.4rem!important;
        width: 1.4rem;
        cursor: pointer;
    }
    &__icon-offset{
        width: 1.4rem;
    }
    &__icon-offset--2{
        width: 2.8rem;
    }
    &__icon-offset--3{
        width: 4.2rem;
    }
    &__icon-offset--4{
        width: 5.6rem;
    }
    &__icon-offset--5{
        width: 7rem;
    }
    &__icon-container {
        display: flex;
        justify-content: center;
    }
    &__dropdown-arrow{
        font-size: 1.4rem!important;
        cursor: pointer;
    }
}

// deprecated
@mixin basic-row{
    @include icons;

    &__container {
        font-size: 1rem;
        display:flex;
        margin: .15rem 0;
        padding: .3rem 0;
        background-color: white;
    }

    &__heading-container{
        font-size: .8rem;
        display:flex;
        padding: .3rem 0;
        background-color: #f0f0f0;
        color:#5e5e5e;
        font-weight: 600;
        // border-left: .12rem solid transparent;
        text-transform: uppercase;
    }
    
    // same styling as above to support selective application
    // of styling for round/row.js 
    &__container--row {
        font-size: 1rem;
        display:flex;
        margin: .15rem 0;
        padding: .3rem 0;
        background-color: white;
        align-items: center;
    }

    &__container--heading {
        font-size: .8rem;
        display:flex;
        padding: .3rem 0;
        background-color: #f0f0f0;
        color:#5e5e5e;
        font-weight: 600;
        // border-left: .12rem solid transparent;
        text-transform: uppercase;
    }

    &__heading-container::before{
        content:"";
        border-left: .12rem solid transparent;
        padding-right: .1rem;
    }

    &__container::before{
        content:"";
        border-left: .12rem solid transparent;
        padding-right: .1rem;
    }

    &__section {
        flex:1;
        display: flex;
        align-items: center;
    }

    &__section--2-block {
        flex: 2;
    }

    &__section--2 {
        flex:2;
        display: flex;
        align-items: center;
    }

    &__section--3 {
        flex:3;
        display: flex;
        align-items: center;
    }

    &__section--4 {
        flex:5;
        display: flex;
        align-items: center;
    }

    &__section--5 {
        flex:5;
        display: flex;
        align-items: center;
    }

    &__a{
        display: flex;
        align-items: center;
    }

    &__label {
        flex:1;
    }
}

// deprecated, use 'card'
@mixin generic-card {
    @include spacing;
    @include borders;

    &__container--border {
        display: flex;
        flex-direction: column;
        border: 1px solid #d2d2d2;
        border-radius:.1rem;
        box-sizing: border-box;
        margin: 0.8rem;
        padding: 0.8rem;
        width: fit-content;
        background-color: white;
    }

    &__container--button {
        display:flex;
    }

    &__header {
        display: flex;
        justify-content: center;
    }

    &__label {
        text-align: center;
        flex: 1;
    }

    &__section {
        flex:1;
    }

    &__section--2 {
        flex:2;
    }

    &__section--3 {
        flex: 3;
    }

    &__section--4 {
        flex: 4;
    }
}

@mixin new-labeled-row {
    @include icons;
    @include text;
    @include spacing;
    @include colors;
    @include borders;
    
    &__container {
        display: flex;
        box-sizing: border-box;
        padding:.8rem;
        margin: .8rem;
        border-radius: .4rem;
        background-color: white;
        box-shadow: 0 2px 1px 0 #ddddddc4;
    }

    &__row {
        display: flex;
        box-sizing: border-box;
        padding: 0rem 1.5rem 0.4rem 1.5rem;
        border-bottom: .1rem solid $gray-border;
        background-color: white;
    }

    // Deprecated, use __row
    &__container--row {
        display: flex;
        box-sizing: border-box;
        padding: 0rem 1.5rem 0.4rem 1.5rem;
        border-bottom: .1rem solid $gray-border;
    }

    &__container--header {
        display: flex;
        box-sizing: border-box;
        padding: 0.8rem 1.5rem;
        border-bottom: .1rem solid $gray-border;
    }
    
    &__container--heading {
        display: flex;
        background-color: white;
        padding: 0.8rem 1.5rem 0rem 1.5rem;
        font-size: .6rem;
        font-weight: 800;
        color:$gray-text;
    }

    &__icon-container{
        display: flex;
        justify-items:flex-end
    }

    &__header {
        margin:.5rem 1rem;
        background-color: white;
    }

    // deprecated. use __container--heading
    &__new-header {
        box-shadow: 0 2px 1px 0 #ddddddc4;
        border: 1px solid #d2d2d2;
        border-radius:.1rem;
        margin:.5rem 1rem;
        box-sizing: border-box;
        background-color: white;
        height: fit-content;
    }

    &__header-lg {
        margin:.5rem 1rem;
        font-size: 1.5rem;
        font-weight: bold;
        background-color: white;
    }
    
    &__section--no-min {
        flex: 1;
    }

    &__section {
        min-width: 10rem;
        flex:1;
    }

    &__section--2 {
        min-width: 10rem;
        flex:2;
    }

    &__section--3 {
        min-width: 10rem;
        flex:3;
    }

    &__label {
        font-size: .6rem;
        font-weight: 800;
        color:$gray-text;
    }

    &__card.nested {
        padding: .8rem 3rem;
    }

    &__dropdown-arrow {
        font-size: 1.4rem!important;
        cursor: pointer;
    }
}

@mixin new-labeled-row-border {
    @include icons;
    @include borders;
    @include spacing;

    &__container {
        display: flex;
        box-sizing: border-box;
        padding:.8rem;
        background-color: white;
        box-shadow: 0 2px 1px 0 #ddddddc4;
    }

    &__icon-container{
        display: flex;
        justify-items:flex-end
    }

    &__header {
        margin:.5rem 1rem;
        background-color: white;
    }

    &__header-lg {
        margin:.5rem 1rem;
        font-size: 1.5rem;
        font-weight: bold;
        background-color: white;
    }

    &__section {
        min-width: 10rem;
        flex:1;
    }

    &__section--2 {
        min-width: 10rem;
        flex:2;
    }

    &__section--3 {
        min-width: 10rem;
        flex:3;
    }

    &__label {
        font-size: .6rem;
        font-weight: 800;
        color:$gray-text;
    }

    &__card.nested {
        padding: .8rem 3rem;
    }

    &__dropdown-arrow {
        font-size: 1.4rem!important;
        cursor: pointer;
    }

}

@mixin generic-form {
    @include icons;

    &__container{
        padding:1rem;
        min-width: 60rem;
    }

    &__section-container{
        flex:1;
        box-sizing: border-box;
        display:flex;
        flex-wrap: wrap;
        padding:.8rem;
        background-color: white;
        align-items: center;
    }

    &__header {
        font-weight: 400;
        font-size: 2rem;
        margin: 10px 0;
        color:$gray-header;
    }
    
    &__styled-button {
        float:right;
        border: .1rem solid $gray-border;
        border-radius: .3rem;
        transition: .2s background-color;
        cursor: pointer;
        padding: .4rem 1rem;
        margin:0 1rem 1rem 1rem;
        color:$gray-text;
        background-color: white;

        :hover{
            background-color: $action-hover;
        }
    }
    
    &__label{
        font-size: .6rem;
        font-weight: 800;
        color:$gray-text;
    }

    &__section{
        flex: 1;
    }
    
}

@mixin background-color-by-role($primary: #005289, $secondary: #65C7C7, $tertiary: #0090B9) {
    
    &.--primary {
        background-color: $primary;
    }
}

@mixin buyer-row{
    @include text;
    
    &__container{
        font-size: 1rem;
        display:flex;
        margin: .15rem 0;
        padding: .3rem 0;
        background-color: white;
    }

    &__container::before{
        content:"";
        border-left: .12rem solid #e3a360;
        padding-right: .1rem;
    }

    &__container--selected{
        background-color: rgba(#0660c1, 0.4)!important;
    }

    &__section{
        display: flex;
        flex:1;
    }

    &__section--2 {
        display: flex;
        flex: 2;
    }
}

@mixin box {
    @include icons;

    &__container {
        margin: 1.5rem;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        background-color: white;
        box-sizing: border-box;
        flex:1;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
        border-radius: 0.4rem;
    }
    &__heading-container{
        font-size: .8rem;
        display:flex;
        padding: .3rem 0;
        background-color: #f0f0f0;
        color:#5e5e5e;
        font-weight: 600;
        text-transform: uppercase;
    }
    &__heading-container::before{
        content:"";
        border-left: .12rem solid transparent;
        padding-right: .1rem;
    }

    &__container::before{
        content:"";
        border-left: .12rem solid transparent;
        padding-right: .1rem;
    }

    &__section {
        padding: .5rem;
        display: flex;
        align-items: center;
        height:2rem;
        background-color: white;
    }

    &__section--2 {
        flex:2;
        display: flex;
        align-items: center;
    }

    &__section--3 {
        flex:3;
        display: flex;
        align-items: center;
    }

    &__section--5 {
        flex:5;
        display: flex;
        align-items: center;
    }

    &__label {
        flex:1;
        display: flex;
    }

    &__section--split{
        padding: .5rem;
        display: flex;
        align-items: center;
        height:2rem;
        background-color: white;
        justify-content: space-between;
    }
}
@mixin order-by {
    @include icons;
    @include positioning;
    @include spacing;
    
    &__container {
        border: 1px solid #d2d2d2;
        margin: .5rem 1rem;
    }

    &__bar {
        display:flex;
        padding: 0.8rem 1.8rem;
        border-top: .1rem solid #c1c5c8;
        border-bottom: .1rem solid #c1c5c8;
        align-items: center;
    }

    &__toggle {
        display: inline-block;
        padding: 0 0.4rem;
    }

    &__section {
        flex:1;
        display: flex;
        align-items: center;
    }

    &__section--2 {
        flex:2;
        display: flex;
        align-items: center;
    }

    &__section--3 {
        flex:3;
        display: flex;
        align-items: center;
    }

    &__section--4 {
        flex:4;
        display: flex;
        align-items: center;
    }

    &__section--5 {
        flex:5;
        display: flex;
        align-items: center;
    }

    &__add-icon {
        // override MUI default
        width: 1.4rem !important;
        color: #1eda1a;
        cursor: pointer;
    }

    &__spacer--dropdown-trigger{
        padding: 0rem 0.3em;
    }

    &--spacer {
        padding-right: 0.9rem;
    }

    &__heading {
        color: #5e5e5e;
        flex: 1;
    }

}

@mixin portfolio-table {
    @include icons;
    @include positioning;
    @include spacing;
    @include text;
    @include colors;
    @include inline-offsets;
    @include overrides;

    &__container {
        display: flex;
    }

    &__container--column--3 {
        display: flex;
        flex: 3;
        flex-direction: column;
    }

    &__container--h1 {
        display: flex;
        align-items: center;
        padding: 0.8rem 0.4rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
    }
    
    // Deprecated, use __heading
    &__container--heading {
        display: flex;
        border-bottom: 0;
        padding: 0.8rem 0.8rem 0.8rem 0.8rem;
        color: #333333;
        background: #E3E3E3;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.4rem;
        @include for-tablet-portrait-down {
            gap: 0.5rem;
            width: max-content;
        }
    }

    &__row {
        display: flex;
        padding: .8rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: $gray-header;
        border-bottom: .1rem solid #d2d2d2;
        gap: 1rem;
    }

    &__row:last-child {
        border-bottom: none;
    }

    // Deprecated, use __row 
    &__container--row {
        display: flex;
        padding: .8rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.4rem;
        color: $gray-header;
        @include for-tablet-portrait-down {
            gap: 0.5rem;
            width: max-content;
        }
    }

    &__container--row:not(:last-child), &__container--heading:not(:last-child) {
        border-bottom: .1rem solid #d2d2d2;
    }

    &__container--dropdown {
        display: flex;
        padding: 0.8rem 0.8rem 0.8rem 0.8rem;
        border-top: .1rem solid #d2d2d2;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.4rem;
        width: 100%;
        align-items: center;
        color: $gray-header;
        @include for-tablet-portrait-down {
            width: max-content;
            gap: 0.5rem;
        }
    }

    &__table {
        margin: 1.5rem;
        border: .1rem solid rgb(210, 210, 210);
        border-radius: 0.4rem;
        box-shadow: 0.25px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
        background: #FFFFFF;
        @include for-tablet-portrait-down {
            overflow: scroll;
        }
    }

    &__table--small {
        margin: 0.8rem;
        border: .1rem solid rgb(210, 210, 210);
        border-radius: 0.4rem;
        box-shadow: 0.25px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
        background: #FFFFFF;
    }

    &--curved-top {
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
    }

    &__heading {
        display: flex;
        border-bottom: 0;
        padding: 0.8rem 0.8rem 0.8rem 0.8rem;
        color: #333333;
        background: #E3E3E3;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.4rem;
        gap: 1rem;
    }

    &__label {
        flex: 1;
        font-weight: 500;
    }

    &__section {
        flex: 1;
        display: flex;
        @include for-tablet-portrait-down {
            min-width: 6.5rem;
        }
    }

    &__section--1\.5{
        flex: 1.5;
        display: flex;
        @include for-tablet-portrait-down {
            min-width: #{1.5 * 6.5}rem;
        }
    }

    &__section--2 {
        flex: 2;
        display: flex;
        @include for-tablet-portrait-down {
            min-width: #{2 * 6.5}rem;
        }
    }
    &__section--3 {
        flex: 3;
        display: flex;
        @include for-tablet-portrait-down {
            min-width: #{3 * 6.5}rem;
        }
    }
    &__section--4 {
        flex: 4;
        display: flex;
        @include for-tablet-portrait-down {
            min-width: #{4 * 6.5}rem;
        }
    }
    &__section--5 {
        flex: 5;
        display: flex;
        @include for-tablet-portrait-down {
            min-width: #{5 * 6.5}rem;
        }
    }
    &__section--6 {
        flex: 6;
        display: flex;
        @include for-tablet-portrait-down {
            min-width: #{6 * 6.5}rem;
        }
    }

    &__section--2 {
        flex: 2;
        display: flex;
    }

    &__dropdown-arrow {

        // must use important to override MUI 
        font-size: 2rem !important;
        width: 1.6rem !important;
        cursor: pointer;
    }
}


@mixin document-modal {
    @include colors;
    &__container--check-box {
        display: flex;
    }

    &__h1 {
        color: black;
        font-weight: bold;
        line-height: 2.4rem;
    }

    &__text {
        display: flex;
        color:black;
        line-height:  1.6rem;
        font-weight: 400;
        align-items: center;
        align-content: center;
    }

}

@mixin buttons {

    &__container--modal {
        display: flex;
        justify-content: space-between;
        margin-top: 0.8rem;
    }

    &__button--basic {
        padding: 0.4rem 1rem;
        border: 0.1rem solid #d2d2d2;
        border-radius: 0.3rem;
        transition: .2s background-color;
        cursor: pointer;
        color: #737373;
        background-color: white;
    }

    &__button--delete {
        padding: 0.4rem 1rem;
        border: 0.1rem solid #d2d2d2;
        border-radius: 0.3rem;
        transition: .2s background-color;
        cursor: pointer;
        background-color: #fe0100;
        color: white;
    }

    &__button--submit {
        padding: 0.4rem 1rem;
        border: 0.1rem solid #d2d2d2;
        border-radius: 0.3rem;
        transition: .2s background-color;
        cursor: pointer;
        background-color: #2B4BF2;
        color: white;   
    }
}
