@import '../colors';

.vesting-section{
    flex:1;
}
.vesting-input-container{
    margin-right: 1rem;
    width:5rem;
}

.vesting-automatic-container{
    display: flex;
    align-items: center;
    font-weight: 800;
    color:$gray-text;
}
.vesting-automatic-logo-selected{
    margin-left: .5rem;
    color:$checkbox-green;
}

.vesting-automatic-logo{
    margin-left: .5rem;
    color:black;
}
