@import './colors';
@import './mixin-modifiers.scss';

.cursor{
    cursor: pointer;
}

.no-scroll-page{
    display:flex;
    flex-direction: column;
    flex:1;
    max-height: 100vh;
    // background-color: rgb(245, 245, 245);
}

.main-app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.styled-datetime > input{
    display: block;
    width:100%;
    box-sizing: border-box;
    padding: .3rem .6rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #1a2e34;
    border: .08rem solid #cdd6e4;
    border-radius: .1875rem;
    background-color: #fff;
    box-shadow: none;
    background-clip: padding-box;
}

.rdtPicker{
    width:fit-content;
    box-sizing: border-box;
    overflow-wrap: normal;
}

.rdtPicker td{
    height: 1rem!important;
}

.rdtSwitch{
    font-size: .8rem!important;
}

.rdtNext{
    height: 1rem!important;
}
.rdtPrev{
    height: 1rem!important;
}
.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: .5rem solid transparent!important;
    border-bottom: .5rem solid #428bca!important;
    bottom: 0!important;
    right: 0!important;
}


.styled-input{
    display: block;
    width:100%;
    box-sizing: border-box;
    padding: .3rem .6rem;
    font-size: 1rem;
    color: #1a2e34;
    border: .08rem solid #cdd6e4;
    border-radius: .1875rem;
    background-color: #fff;
    box-shadow: none;
    background-clip: padding-box;
}

.slim-button{
    padding: .2rem .4rem!important;
}

.styled-textarea{
    width:100%;
    min-height: 3rem;
    resize: none;
}

.capitalize{
    text-transform: capitalize;
}
.text {
    font-weight: 400;
    line-height: 1.6rem;
    &--white {
        color: var(--white);
    }
}
.error{
    font-size: .8rem;
    min-height:1rem;
    color:red;
}

%styled-button{
    padding: .4rem 1rem;
    border: .1rem solid $gray-border;
    border-radius: .3rem;
    transition: .2s background-color;
    color:$gray-text;
    cursor: pointer;
    background-color: white;

    :hover{
        background-color: $action-hover;
    }
}

.styled-button {
    @extend %styled-button;
}

.hacky-button{
    display:none;
}

.none-found{
    color:$gray-text;
    text-align: center;
    font-style: italic;
}

.flex-0 {
    flex: 0;
}

.flex-1{
    flex:1;
}

.flex-2{
    flex:2;
}
.flex-3{
    flex:3;
}
.flex-4{
    flex:4;
}
.flex-5{
    flex:5;
}
.flex-6{
    flex:6;
}
.flex-7{
    flex:7;
}
.flex-10{
    flex:10;

}
.center-row{
    display:flex;
    align-items: center;
}
.float-right{
    margin-left: auto;
}
.row {
    @include spacing;
    display:flex;
    // deprecate below
    &--justify-end {
        justify-content: flex-end;
    }
    &--justify-between {
        justify-content: space-between;
    }
}

.column{
    display:flex;
    flex-direction: column;
}

.margin{
    margin:1rem;
}
.margin-right{
        margin-right: 1rem;
}

.paddingbox {
    margin: .5rem;
    padding:.5rem;
    border: 1px solid #a2a9ad;
}

.clickable:hover {
    cursor: pointer;
}

svg.action-icon {
    transition: color .2s ease;
    color: gray;
    font-size: 1.75rem;
}

svg.action-icon:hover {
    color: $blue-dark;
}
.header{
    color:$gray-header;
    font-weight: 400;
    margin:1rem 0;
}
.no-margin-header{
    color:$gray-header;
    font-weight: 400;
    margin:0!important;
}
.relative{
    position: relative;
}
.absolute-error{
    position: absolute;
    font-size: .8rem;
    height:1rem;
    color:red;
    top:-1rem;
    background-color: white;
}
.margin-auto{
    margin:auto;
}
.margin-vertical-center{
    margin: auto 0;
}
.no-margin{
    margin: 0!important;
}
.vrtl-icon{
    width: 1.4rem!important;
    height: 1.4rem!important;
    font-size: 1.4rem!important;
}

// inline-offsets deprecated. use mixin-modifier.
.inline-offset-half {
    width: 0.8rem;
    display: inline-block;
}

.inline-offset--single{
    width: 1rem;
    display: inline-block;
}

.inline-offset {
    width: 1.6rem;
    display: inline-block;
}

.inline-offset--2 {
    width: 3.6rem;
    display: inline-block;
}

.inline-offset--3 {
    width: 5.2rem;
    display: inline-block;
}

.icon-offset{
    width: 1.4rem!important;
}
.icon-offset--2{
    width: 2.8rem!important;
}
.icon-offset--3{
    width: 4.2rem!important;
}

.brand-bg-primary-color{
    background-color:#004a98;
}

.brand-bg-text-overlay-color{
    color:white;
}

.pointer {
    cursor: pointer;
}

.popup-contents{
    font-size: .6rem;
    font-weight: 800;
    color:$gray-text;
}

.PhoneInput{
    font-size: 1.3rem!important;
}

.PhoneInput input{
    font-size: 1.3rem!important;
}
