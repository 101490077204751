.side-nav-container{
    position: relative;

}
.side-nav{
    z-index: 99;
    width:15rem;
    height:100%;
    position: absolute;
    height: 100%;
    top:0;
    left:0;
    transition: .3s;
}

.side-nav.hide{
    left:-15rem;
}

.side-nav-row{
    padding:.2rem 1rem .5rem 1.5rem;
    display:flex;
    color:rgba(255, 255, 255, .5);
    cursor: pointer;
}

.side-nav-item{
    letter-spacing: .05rem;
    text-decoration: none!important;
    text-transform: uppercase;
}

.side-nav-row.active{
    color:#0660c1;
    background-color: white;
}

.side-nav-expand-open{
    opacity: .7;
    position: absolute;
    top:40%;
    left: 99%;
    background-size: 2000%;
    border-radius:0 50% 50% 0;
    color:white;
    cursor: pointer;
    transition: .5s;
}

.side-nav-expand-open:hover{
    opacity: 1;
}

.side-nav-expand-close{
    opacity: 1;
    position: absolute;
    top:40%;
    left:13rem;
    background: white;
    background-size: 2000%;
    border-radius:50% 0 0 50%;
    color:#0660c1;
    cursor: pointer;
    transition: .5s;
}

.side-nav-expand-open svg{
    font-size: 2rem;
}

.side-nav-expand-close svg{
    font-size: 2rem;
}


.side-nav-spacer{
    margin:0 .6rem;
}

.side-nav-header{
    padding:.2rem 1rem .5rem 1.5rem;
    display:flex;
    color:rgba(255, 255, 255, 0.5);
}

.brand-bg-primary-color {
    background-color: #005289;
}

.brand-bg-secondary-color {
    background-color: #65C7C7;
}

.brand-bg-tertiary-color {
    background-color: #0090B9;
}