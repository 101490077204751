@import'../colors';

.order-by-bar{
    display:flex;
    background-color: #f4f4f5;
    padding: .6rem 1rem;
    border-bottom: .1rem solid #c1c5c8;
}

.order-by-bar > div{
    display: flex;
    align-items: center;
}

.order-by-field{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    color:$gray-header;
    flex:1;
}

.order-by-row{
    color:$gray-header;
    flex:1;
    display: flex;
    align-items: center;
}


.order-by-input{
    margin-left: .5rem;
    margin-right: .5rem;
    // max-width: 10rem;
}
.order-by-checkbox-container{
    padding-right: .3rem;
    display: flex;
    align-items: center;
}
.order-by-checkbox-container *{
    font-size: 1.2rem!important;
}
.order-by-checkbox{
    color:$checkbox-green;
}
.order-by-inner{
    flex:1;
    overflow-y: auto;
    padding-top: .5rem;
    margin: 0 1rem 1rem 1rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.asc-sort-icon{
    transform: rotateX(180deg);
}
.order-by-dropdown{
    margin: 0 .5rem;
    flex:1;
}
