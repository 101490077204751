
@import '../colors';

.bulk-annotation-form{
    min-width: 10rem;
    min-height: 10rem;
    display:flex;
    flex-direction: column;
}

.bulk-annotation-form-section{
    flex: 1;
    min-width: 15rem;
    min-height: 10rem;
}

.bulk-annotation-card-label {
    font-size: 0.9em;
    padding-bottom: 0.25em;
}


.bulk-form-button-container {
    margin: 0 0 .5rem 8rem;
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    min-width: 5em;
}

.bulk-form-button {
    max-width: 5rem;
    min-width: 5em;
    justify-content: flex-end;
    padding: .4rem 1rem;
    border: .1rem solid $gray-border;
    border-radius: .3rem;
    transition: .2s background-color;
    color:$gray-text;
    cursor: pointer;
    text-align: center;
}

.bulk-form-button:hover{
    background-color: $action-hover;
}