.vrtl-signing-templates__page{
    display:flex;
    flex:1;
}
.vrtl-signing-templates__left{
    margin-top: .5rem;
    flex:1;
    display: flex;
    flex-direction: column;
}
.vrtl-signing-templates__left-body{
    flex:1;
    position: relative;
}
.vrtl-signing-template__back{
    padding:.5rem;
    font-size: 1.5rem;
}
.vrtl-signing-templates__right{
    display: flex;
    flex-direction: column;
    flex:4;
}
.vrtl-signing-templates__container--templates{
    display: flex;
    flex-direction: column;
    flex:1;
}
.vrtl-signing-left__section{
    padding:0 .5rem .5rem .5rem;
    margin-bottom: .5rem;
    background-color: white;
    border-bottom: 2px solid black;
    border-right: 2px solid black;

}
.vrtl-signing__header{
    color: #5b6670;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.vrtl-signing__button{
    max-width: 10rem!important;
    margin: 0 auto;
}

.vrtl-signing__button:hover{
    cursor: pointer;
}
.vrtl-signing-templates__container--roles{
    display: flex;
    flex-direction: column;
    flex:1;
    margin-left: 1rem;
}

.vrtl-signing-templates__container--questions{
    display: flex;
    flex-direction: column;
    flex:1;
    margin-left: 1rem;
    margin-top: 1rem;
}

.vrtl-signing-templates__container--sent-documents{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    flex:2;
}
