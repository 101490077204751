@import '../colors';
@import '../mixins.scss';

.vrtl-price-card {
    @include new-labeled-row;
}

.price-form-card{
    flex:1;
    box-sizing: border-box;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.8rem 0rem 0.8rem 0.8rem;
    background-color: white;
    font-size: 1.2rem;
}

.price-ppu-container{
    margin-right: 1rem;
}

.buyer-form-header{
    margin: 0 5px;
    color:$gray-header;
}
.price-form-button-container{
    margin:0 .8rem .5rem .8rem;
    display: flex;
    justify-content: space-between;
}

.price-form-button{
    max-width: 5rem;
    padding: .4rem 1rem;
    border: .1rem solid $gray-border;
    border-radius: .3rem;
    transition: .2s background-color;
    color:$gray-text;
    cursor: pointer;
    text-align: center;
}
.price-form-button:hover{
    background-color: $action-hover;
}
