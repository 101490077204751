.token-form{
    min-height: 12rem;
    min-width: 45rem;
    padding:1rem;
    display: flex;
    flex-direction: column;
}
.token-form-chain-conatiner{
    display:flex;
    padding:.8rem;
    /* align-items: center; */
}
.token-form-input{
    max-width: 10rem;
}
.token-form-integration-container{
    display: flex;
    flex:2;
}
.token-form-chain-section{
    flex:1;
    min-height: 8rem;
    margin: .5rem .5rem .5rem 0;
    display: flex;
    flex-direction: column;
}
.token-form-old-chain-section{
    flex:1;
    margin: .5rem .5rem .5rem 0;
}
.token-form-integration-row{
    /* border:2px solid gray; */
    margin: .1rem;
    display: flex;
}
.token-form-integration-chain{
    flex:1;
}
.token-form-integration-name{
    flex:1;
}

.vrtl-option-select-row{
    display: flex;
    align-items: center;
}
