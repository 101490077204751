@import 'colors';

@mixin positioning {
    &--col {
        flex-direction: column;
    }

    &--flex {
        display: flex;
    }
    
    &--block {
        display: block;
    }

    &--center {
        align-items: center;
        justify-content: center;
    }

    &--align-center {
        align-items: center;
    }

    &--justify-end {
        justify-content: flex-end;
    }

    &--justify-between {
        justify-content: space-between;
    }

    &--center-self {
        align-self: center;
    }

    &--flex-end--self {
        align-self: flex-end;
    }
}

@mixin inline-offsets {
    &__inline-offset-half {
        width: 0.8rem;
        display: inline-block;
    }
    
    &__inline-offset--single{
        width: 1rem;
        display: inline-block;
    }
    
    &__inline-offset {
        width: 1.4rem;
        display: inline-block;
    }
    
    &__inline-offset--2 {
        width: 2.8rem;
        display: inline-block;
    }
    
    &__inline-offset--3 {
        width: 4.2rem;
        display: inline-block;
    }
}

@mixin spacing {

    &--gap {
        gap: 1rem;
    }

    &--padding {
        padding: 0.8rem;

        &-sm {
            padding: 0.4rem;
        }

        &-lg {
            padding: 1.6rem;
        }

        &-left{
            padding-left: 0.8rem;

            &-sm {
                padding-left: 0.4rem;
            }

            &-lg {
                padding-left: 1.6rem;
            }
        }

        &-right{
            padding-right: 0.8rem;

            &-sm {
                padding-right: 0.4rem;
            }

            &-lg {
                padding-right: 1.6rem;
            }
        }

        &-top{
            padding-top: 0.8rem;

            &-sm {
                padding-top: 0.4rem;
            }

            &-lg {
                padding-top: 1.6rem;
            }
        }

        &-bottom{
            padding-bottom: 0.8rem;

            &-sm {
                padding-bottom: 0.4rem;
            }

            &-lg {
                padding-bottom: 1.6rem;
            }
        }
    }

    &--margin {
        margin: 0.8rem;

        &-sm {
            margin: 0.4rem;
        }

        &-lg {
            margin: 1.6rem;
        }

        &-left{
            margin-left: 0.8rem;

            &-sm {
                margin-left: 0.4rem;
            }

            &-lg {
                margin-left: 1.6rem;
            }
        }

        &-right{
            margin-right: 0.8rem;

            &-sm {
                margin-right: 0.4rem;
            }

            &-lg {
                margin-right: 1.6rem;
            }
        }

        &-top{
            margin-top: 0.8rem;

            &-sm {
                margin-top: 0.4rem;
            }

            &-lg {
                margin-top: 1.6rem;
            }
        }

        &-bottom{
            margin-bottom: 0.8rem;

            &-sm {
                margin-bottom: 0.4rem;
            }

            &-lg {
                margin-bottom: 1.6rem;
            }
        }
    }

}

@mixin borders {
    &--border {
        border: 1px solid #d2d2d2;
        border-radius:.1rem;
    }

    &--border-bottom {
        border-bottom: 1px solid $gray-border;
    }

    &--curved-top {
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
    }
  
    &--border-light {
        border: .1rem solid #d2d2d2;
    }

    &--border-white {
        border: .1rem solid white;
    }

    &--border-gray {
        border: $gray-border
    }
}

@mixin elevation {
    &--no-shadow {
        box-shadow: none;
    }
}

@mixin colors {
    &--bg-white {
        background-color: white;
    }

    &--hover-highlight:hover {
        color: $blue-main;
        cursor: pointer;
    }


    
    &--vtlo-blue-light {
        background-color: $vtlo-blue-light;
    }
    
    &--vtlo-teal {
        background-color: $vtlo-teal;
    }

    &--orange {
        background-color: $orange;
    }
}

@mixin overrides {
    //MUI overrides

    &__add-icon {
        width: 1.4rem !important;
        color: #1eda1a;
        cursor: pointer;
    }

    &__dropdown-arrow {
        font-size: 2rem !important;
        width: 1.6rem !important;
        cursor: pointer;
    }

    &--vrtl-blue-fill {
        fill: #004A98 !important;
    }

    &--bg-white {
        background-color: white !important;
    }

    &--hover-highlight:hover {
        color: $blue-main  !important;
    }

    &--vrtl-blue {
        background-color: #004A98 !important;
    }
    
    &--vtlo-blue-light {
        background-color: $vtlo-blue-light  !important;
    }
    
    &--vtlo-teal {
        background-color: $vtlo-teal  !important;
    }

    &--icon-size-sm {
        width: 1.4rem !important;
        height: 1.4rem !important;
    }

    &--icon-size-xsm {
        width: 1.25rem !important;
        height: 1.25rem !important;
    }
}

@mixin text {
    font-weight: 400;
    line-height: 1.6rem;

    &--pascal {
        text-transform: capitalize;
    }

    &--text-xsm {
        font-size: .6rem;
        font-weight: 600;
        color:$gray-text;

    }
    
    &--text-sm {
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.2rem;
    }

    &--text-lg{
        font-size: 2rem;
        font-weight: 500;
        line-height: 2rem;
    }

    &--text-xl{
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 2.4rem;
    }

    &--error {
        color: red;
        font-size: 0.8rem;
        font-weight: 300;
        line-height: 1.2rem;
    }

    &--overflow-wrap {
        overflow-wrap: anywhere;
    }
}

@mixin shapes {
    &__circle {
        margin: 0.2rem;
        height: 0.8rem;
        width: 0.8rem;
        border-radius: 50%;
        display: inline-block;
    }
}