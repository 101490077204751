.dropdown-trigger-container{
    width: 100%;
}

.dropdown-trigger{
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: .3rem .6rem;
    font-size: 1rem;
    color: #1a2e34;
    border: .08rem solid #cdd6e4;
    border-radius: .1875rem;
    background-color: white;
}
.dropdown-items{
    overflow-y: auto;
    max-height: 16rem;
    position: absolute;
    right:0;
    left:0;
    z-index: 3;
    background-color: white;
    border: .08rem solid #cdd6e4;
    border-radius: .1875rem;
}

.dropdown-item{
    padding:.2rem .5rem;
    cursor: pointer;
    text-transform: capitalize;
    transition: .2s background-color;
    font-size: 1rem;
}

.dropdown-item:hover{
    background-color: rgb(217, 217, 217);
}

.vrtl-dropdown-typeahead-container{
    position: relative;
    display:flex;
    justify-content: space-between;
    padding: .3rem .6rem;
    align-items: center;
    box-sizing: border-box;
    color: #1a2e34;
    border: .08rem solid #cdd6e4;
    border-radius: .1875rem;
    background-color: white;
    margin-right: 1rem;
}
.vrtl-dropdown-typeahead-container > input{
    font-size: 1rem;
    width: 100%;
    border:none!important;
    outline: none;
}

.vrtl-dropdown__icon--typeahead{
    position: absolute;
    right:0;
    cursor: pointer;
    font-size: 1rem;
    z-index: 2;
    background-color: white;
}
