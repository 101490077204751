@import '../colors';

@mixin text{
    &--text {
        font-style: normal;
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 0.9rem;
    }

    &__sub-text{
        font-style: normal;
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: #5e5e5e;
    }

    &__heading {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 2rem;
    }
    
    &__sub-heading {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.6rem;
    }

    &__label {
        font-style: normal;
        font-weight: bold;
        font-size: 0.8rem;
        line-height: 1.5rem;
    }   

    &__content {
        font-style: normal;
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 0.9rem;
    }

    &__content--white {
        font-style: normal;
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 0.9rem;
        color: rgba(255,255,255,0.8);
    }
}
