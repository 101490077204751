.test{
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.test-textarea{
    width: 90vw;
    height:50vh;
}
