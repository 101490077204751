@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: "Inter", "Roboto", -apple-system, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h2{
    margin: 1rem 0;
}

:root {
    --login-logo-height: 4rem;
    --nav-logo-height: 1.5rem;
}
