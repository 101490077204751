@import '../colors';
@import '../mixin-modifiers.scss';

.vrtl-upload__file{
    display: flex;
    align-items: center;
}
.vrtl-upload__container--files{
    min-height: 3rem;
}

.vrtl-upload__container--buttons {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 400;
}

.vrtl-upload__dropzone {
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    border-radius: .2rem;
    padding:1rem;
    margin:.8rem .2rem .2rem .2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    transition: .2s background-color;
}

.vrtl-upload__dropzone:hover {
    background-color: $action-hover;
}
.vrtl-upload__dropdown-hover{
    background-color: $action-hover
}
.vrtl-upload__dropzone--text{
    color: rgba(21, 25, 32, 0.5);
    font-weight: 600;
    font-size: 0.8rem;
    margin: 0rem 0.4rem 0rem 0.4rem;
}

.vrtl-upload__dropzone.disabled{
    transition: 0s background-color!important;
    background-color: $gray-text;
}

.vrtl-upload__hacky-file-input {
    display: none;
    cursor: default!important;
}
.vrtl-upload__progress-bar {
    flex:1;
    margin: 0 1rem;
    box-sizing: border-box;
    height: 0.25rem;
    background-color: gray;
    border-radius: 5px;
}

.vrtl-upload__progress {
    height: 0.25rem;
    margin: 0;
    border-radius: 5px;
}

.vrtl-upload__buttons{
    max-width: 3rem;
    margin-top: 1.6rem;
    padding: 0.4rem 1rem;
    border: 0.1rem solid #d2d2d2;
    border-radius: 0.3rem;
    transition: .2s background-color;
    cursor: pointer;
}

.vrtl-upload__cancel{
    background-color: white;
    color: black !important;
}

.vrtl-upload__submit {
    background-color: #2B4BF2;
    color: white;
}

// MUI cloud-upload override
.vrtl-upload__upload-icon {
    color: #5e5e5e;
}

.vrtl-upload__icon--cancel {
    cursor: pointer;
    fill: red !important;
}
