.vrtl-number__container{
    display: flex;
    align-items: center;
}
.vrtl-number__icon{
    font-size: 1.1rem!important;
    color:rgb(189, 187, 0);
}
.vrtl-number__tooltip-content{
    background-color: #5b6269;
    color:white;
}
