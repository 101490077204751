@import '../colors';

.vrtl-process__container{
    box-shadow: 0 2px 1px 0 #ddddddc4;
    border: 1px solid #d2d2d2;
    border-radius: .1rem;
    margin: .5rem 1rem;
    box-sizing: border-box;
    background-color: white;
    flex:1;
    display: flex;
    flex-direction: column;
}

.vrtl-process-iframe__container{
    min-width: 50vw;
    min-height: 50vh;
    display: flex;
}
.vrtl-process-iframe{
    flex:1;
    border: none !important;
    height:100%;
    width: 100%;
}
.hidden-popup{
    background: rgba(0, 0, 0, 0);
    color:white;
}

.vrtl-step-card__requirement-row {
    flex:1;
    display: flex;
}

.white-loading{
    color:white!important;
}
.white-loading > .loading-text{
    color:white!important;
}
.vrtl-step-card__header {
    display: flex;
    justify-content: space-between;
    color: $gray-header;
    border-bottom: 0.1rem solid #d2d2d2;
    padding: 0.2rem 0.5rem;
    font-weight: 600;
}

.vrtl-step-card__logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 5rem;
}

.vrtl-step-card__logo-container > div{
    min-width: 50%;
}

.vrtl-step-card__logo {
    width: 100%;
}

.vrtl-step-card--body {
    display: flex;
    padding: 0.5rem 1rem;
}

.vrtl-step-card--requirements-list {
    margin: 0 0 0 1rem!important !important;
}

.vrtl-step-card__footer {
    margin: 1rem;
}

.vrtl-step-card--action-btn {
    text-align: center;
    background-color: #008FBE;
    cursor: pointer;
    color: white;
    font-weight: 600;
    border-radius: 0.2rem;
    padding: .7rem 2rem;
    max-width: 30rem;
    margin: 0 auto;
}

.vrtl-process-short-steps{
    padding:.5rem 1rem;
    flex:1;
}

.vrtl-step-card__requirement-icon--current_step_on{
    font-size: 2rem!important;
    color: green;
    visibility: visible;
}

.vrtl-step-card__requirement-icon--current_step_off{
    font-size: 2rem!important;
    color: white;
    visibility: hidden;
}

.vrtl-step-card__requirement-icon--not_started{
    font-size: 1rem!important;
    color: green;
    // margin: .5rem;
}

.vrtl-step-card__requirement-icon--in_progress {
    font-size: 1rem!important;
    color: rgb(55, 185, 241);
    color: green;
    // margin: .5rem;
    animation: spin 3s linear infinite;
}

.vrtl-step-card__requirement-icon--complete {
    font-size: 1rem!important;
    color: green;
    // margin: .5rem;
}

.vrtl-step-card__requirement-icon--restart{
    font-size: 1.1rem!important;
    color: orange;
    // margin: .5rem;
}

.vrtl-step-card__requirement-icon--retry{
    font-size: 1.1rem!important;
    color: rgb(195, 250, 77);
    // margin: .5rem;
}

.vrtl-step-card__requirement-icon--retry_limit{
    font-size: 1rem!important;
    color: orange;
    // margin: .5rem;
}

.vrtl-step-card__requirement-icon--failed {
    font-size: 1rem!important;
    color: red;
    cursor: pointer;
    // margin: .5rem;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(-360deg);
    }
}

.vrtl-async-iframe__link-container{
    background-color: white;

}

.vrtl-step-card__progress-bar{
    display: flex;
    padding: 0 1rem;
    flex-direction: column;
    &-container{
        display: flex;
        margin-top: -.5rem;
    }
    &-fill{
        position: relative;
        z-index: 3;
        top:1.75rem;
        background-color: #004a98;
        border-radius: 1rem;
        height: .75rem;
    }
    &-item{
        flex:1;
        display:flex;
        flex-direction: column;
        &-icon{
            font-size: 2.5rem!important;
            cursor: pointer;
        }
        &-icon-container{
            position: relative;
            margin:0 auto;
            z-index: 30;
        }
        &-status-icon{
            background-color: white;
            border-radius: 1rem;
            position: absolute;
            bottom:0;
            right:0;
            height:1rem;
            width: 1rem;
        }
        &-name{
            text-align: center;
        }
        &-name.selected{
            text-decoration: underline;
            font-weight: 600;
        }
    }
}

.vrtl-step-number{
    text-align: center;
    padding:1rem;
    font-size: 1.8rem;
    line-height: 1.2rem;
    color: white;
    width:3.5rem;
    height: 3.5rem;
    box-sizing: border-box;
    border-radius: 2rem;
    cursor: pointer;
    border: .2rem solid #004a98;
    color:#004a98;
    background-color: white;
}

.vrtl-step-number.active{
    background-color:#004a98 !important;
    color:white;
}

.vrtl-process-step{
    &__container{
        display: flex;
        flex-direction: column;
        flex:1;
    }
    &__header{
        color:$gray-header;
        font-weight: 400;
        margin:1rem 0 .5rem 0;
        text-align: center;
        font-size: 2.5rem;
    }

    &__body{
        display:flex;
        flex:1;
        padding: .5rem;
        &--left{
            padding-right: .5rem;
            flex:3;
        }
        &--right{
            padding-left: .5rem;
            flex:7;
        }
    }
    &__instructions{
        padding-left: 1rem;
    }
    &__inactive{
        display: flex;
        align-items: center;
        height: 100%;
        background-color: gray;
    }
    &__inactive--text{
        padding: 0 1rem;
        color: white;
    }
}
