@import '../media-mixins';
@import '../colors';

.vrtl-account-role-hero__container{
    display:flex;
    justify-content: space-between;
    background-color: white;
    padding: 1rem 0;
}

.vrtl-account-role-hero__logo-container{
    flex:4;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.vrtl-accoount-role-hero__left{
    flex:1;
    padding-left: 2rem;
}

.vrtl-account-role-hero__logout-container{
    flex:1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 2rem;
}

.vrtl-account-role-hero__logout-container > a{
    text-decoration: none;
    color:$gray-header;
    display: flex;
    align-items: center;
}

.account-role-hero-logo{
    max-width: 4rem;
    max-height: 4rem;
}

.account-role-hero-text{
    margin:0;
    margin-top: 3rem;
    font-weight:400;
    color:$gray-header;
}

.vrtl-terms__container{
    max-width: 42rem;
    background-color: white;
    padding:.5rem;
    overflow-y: auto;
    margin: 1rem auto;
    flex:9;
    border: 1rem solid white;
}

.vrtl-terms-action__container{
    max-width: 42rem;
    margin: 0 auto;
    flex:1;
}

.account-role{
  height:100vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.delegate-role-logo {
    height:2rem;
}
.delegate-role-logo svg{
    height:2rem;
}

.delegate-role{
    background-color: #e9ecef7a;
    display: flex;
    padding:1rem 1.5rem;
    min-width: 21rem;
    transition: .2s background-color;
    align-items: center;
    cursor: pointer;
}

.delegate-role:hover{
    background-color:rgb(191, 191, 191);
}

.delegate-role:nth-child(n+2){
    border-top: 2px solid #fafafa;
}

.delegate-claim{
    border-width: 0.1rem;
    border-style: solid;
    border-color: $gray-header;
}

.delegate-role-avatar{
  height:2.5rem;
  width: 2.5rem;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.delegate-role-name{
    color:#666;
    font-size: 1.5em;
    font-weight: 200;
    margin: .2rem 0;
}

.account-role-name{
    color:#666;
    font-weight: bold;
}

.delegate-role-text-container{
  margin-left: 1rem;
}

.delegate-role-role{
    color:gray;
    text-transform: capitalize;
}

.delegate-role-claim{
    text-transform: none!important;
}

.vrtl-account-role{
    &__body{
        display: flex;
        width: 100%;
        flex:1;
    }
    &__left{
        flex:3;
    }
    &__right{
        flex:3;
    }


    &__delegates-body{
        position: relative;
        min-width: 77rem;
        @include for-tablet-portrait-down {
            min-width: 100%;
        }
    }

    &__actions--container{
        margin-top: .5rem;
        display: flex;
        justify-content: center;
    }

    &__claim-link{
        text-decoration: none;
    }
}
